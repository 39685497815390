import React from "react";
import parse from "html-react-parser";
import { Link } from "gatsby";
import Img from "gatsby-image";

class CaseStudy extends React.Component {
  render() {
    const defaultPost = {
      post: {
        featuredImage: {
          imageFile: {
            publicURL: "/images/case-studies/placeholder.jpg",
          },
        },
      },
    };

    let {
      post: {
        title,
        uri: link,
        termSlugs: categories,
        acf_subtitle: { subtitle },
        acf_case_study_images: { view, listingImage },
      },
    } = this.props;

    // featuredImage = featuredImage || defaultPost.post.featuredImage;

    var cats = "";
    if (categories) {
      cats = [...new Set(categories.map((q) => q)), view];
      cats = cats.join(" ");
    }
    // console.log(this.props);

    return (
      <div className={`col-md-4 post ${cats}`}>
        <Link className="direction-reveal__card" to={`/portfolio/${link}`}>
          {listingImage ? (
            <Img
              className="bg"
              fluid={listingImage.imageFile.childImageSharp.fluid}
              alt="image"
              fadeIn={false}
            />
          ) : (
            <img
              className="bg"
              src={defaultPost.post.listingImage.imageFile.publicURL}
              alt={parse(title)}
            />
          )}
          <span className="direction-reveal__overlay direction-reveal__anim--in">
            <h3 className="direction-reveal__title">{parse(title)}</h3>
            {subtitle && (
              <p className="direction-reveal__text">{parse(subtitle)}</p>
            )}
          </span>
        </Link>
      </div>
    );
  }
}

export default CaseStudy;
