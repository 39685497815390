import React from "react";
import { graphql } from "gatsby";

import Layout from "../layouts/home";
import SEO from "../components/SEO";

import contentParser from "gatsby-wpgraphql-inline-images";
import { inlineUrls } from "../utils";
import Social from "../components/Social/sharer";
import CaseStudies from "../components/CaseStudies/listing";
import WorkWithUs from "../components/Forms/workwithus";

class Portfolio extends React.Component {
  render() {
    const {
      location,
      data: {
        wordPress: {
          pageBy: {
            acf_subtitle: { subtitle },
            excerpt,
          },
        },
      },
    } = this.props;

    // console.log(this.props);

    return (
      <Layout location={location} cssClass="casestudies">
        <SEO title="Portfolio" description={excerpt} />
        <section id="header" className="p-top p-bottom text-center bg-charcoal">
          <div className="featured">
            <h1>Portfolio</h1>
            <div className="lead">
              <p>{subtitle}</p>
            </div>
          </div>
        </section>
        <section id="excerpt" className="bg-light-gray p-top p-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-12 lead line text-center">
                {contentParser({ content: excerpt }, inlineUrls)}
              </div>
            </div>
          </div>
        </section>
        <Social location={location} />
        <CaseStudies />
        <WorkWithUs />
      </Layout>
    );
  }
}

export default Portfolio;

export const portfolioQuery = graphql`
  {
    wordPress {
      pageBy(uri: "about") {
        acf_subtitle {
          subtitle
        }
        excerpt
      }
    }
  }
`;
