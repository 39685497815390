import React from "react";
import { graphql, StaticQuery } from "gatsby";
import DirectionReveal from "direction-reveal";

import CaseStudy from "../CaseStudy";
import CaseStudiesFilters from "./filters";

const Isotope =
  typeof window !== `undefined` ? require("isotope-layout") : null;

class CaseStudies extends React.Component {
  constructor(props) {
    super(props);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.state = {
      selectedFilter: "brand-development",
    };
  }

  componentDidMount() {
    this.onFilterChange(this.state.selectedFilter);
    this.iso.arrange({
      filter: `.${this.state.selectedFilter}`,
    });

    // Init with all options at default setting
    DirectionReveal({
      selector: ".direction-reveal", // Container element selector.
      itemSelector: ".direction-reveal__card", // Item element selector.
      animationName: "slide", // Animation CSS class.
      enableTouch: true, // Adds touch event to show content on first click then follow link on the second click.
      touchThreshold: 250, // Touch length must be less than this to trigger reveal which prevents the event triggering if user is scrolling.
    });
  }

  componentDidUpdate() {
    this.iso.arrange({ filter: `.${this.state.selectedFilter}` });
  }

  onFilterChange = (newFilter) => {
    if (this.iso === undefined) {
      this.iso = new Isotope("#casestudies", {
        itemSelector: ".post",
        percentPosition: true,
        horizontalOrder: true,
        masonry: {
          columnWidth: ".grid-sizer",
          gutter: 0,
        },
      });
    }

    this.setState({
      selectedFilter: newFilter,
    });
  };

  render() {
    const {
      data: {
        wordPress: {
          casestudies: { edges: posts },
        },
      },
    } = this.props;

    // console.log(this.props.data.wordPress.caseStudies.edges);

    return (
      <>
        <CaseStudiesFilters
          selectedFilter={this.state.selectedFilter}
          onFilterChange={this.onFilterChange}
        />

        <section className="mt-0 bg-charcoal case-studies">
          <div className="container">
            <div
              className={`row no-gutters direction-reveal direction-reveal--grid-bootstrap direction-reveal--demo-bootstrap grid ${this.state.selectedFilter}`}
              id="casestudies"
            >
              <div className="grid-sizer"></div>
              {posts &&
                posts.map((post) => {
                  // console.log(post.node.casestudyId);
                  return (
                    <CaseStudy key={post.node.casestudyId} post={post.node} />
                  );
                })}
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        wordPress {
          casestudies(first: 100, after: null) {
            edges {
              node {
                casestudyId
                title
                uri
                acf_subtitle {
                  subtitle
                }
                termSlugs(taxonomies: TAX_SERVICE)
                acf_case_study_images {
                  view
                  listingImage {
                    altText
                    sourceUrl(size: MEDIUM_LARGE)
                    content
                    mediaItemId
                    modified
                    imageFile {
                      extension
                      publicURL
                      childImageSharp {
                        fluid(maxWidth: 800) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <CaseStudies data={data} />}
  />
);
