import React from "react";
import { StaticQuery, graphql } from "gatsby";
import parse from "html-react-parser";

class TaxSectors extends React.Component {
  render() {
    // console.log(this.props);
    const { data } = this.props;
    return (
      <section className="mt-0 bg-charcoal">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <ul className="sectors">
                {data &&
                  data.wordPress.tax_services.edges.map((item, index) => {
                    return (
                      <li key={index}>
                        <a
                          href={`#${index}`}
                          data-filter={item.node.slug}
                          className={
                            this.props.selectedFilter === item.node.slug
                              ? "active"
                              : null
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.onFilterChange(item.node.slug);
                          }}
                        >
                          {parse(item.node.name)}
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      {
        wordPress {
          tax_services(first: 90, after: null, where: { hideEmpty: true }) {
            edges {
              node {
                name
                slug
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <TaxSectors
        data={data}
        selectedFilter={props.selectedFilter}
        onFilterChange={props.onFilterChange}
      />
    )}
  ></StaticQuery>
);
